import { Injectable } from '@angular/core';
import { v4 as uuid } from 'uuid';
import { DebugService } from '../debug-service/debug.service';
import { ErrorService } from '../error-service/error.service';
import { Search } from 'src/app/classes/warrants/search/search';

@Injectable({
  providedIn: 'root'
})
export class UtiltyService {
  /** Name of the service */
  private serviceName = 'utility-service';

  constructor(
    private debugSrvice: DebugService,
    private errorService: ErrorService
  ) { }

  /** Create a deep copy of any JS/JSON objecy
   * @param object The object ot be copied
   * @returns The same object (not as a pointer)
   */
  public primativeCopy = (object: any) => {
    return JSON.parse(JSON.stringify(object));
  }

  /**
   * Method to use with array.sort to sort the array by sort order
   * @param a Object 1
   * @param b Object 2
   * @returns Order of the two objects
   */
  public sortOrderCompate = (a: any, b: any) => {
    if(a.sortOrder < b.sortOrder) return -1;
    if(a.sortOrder > b.sortOrder) return 1;
    return 0;
  }

  /**
   * Capitalize the first letter in a string
   * @param string String to be capitalized
   * @returns Capitalized string
   */
  public capitalizeString = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  /**
   * Convert an array of strings to one long string
   * @param map Array of strings to be stringified
   * @returns A string of the array without commas
   */
  public stringFromMap = (map: string[]) => {
    let newString = '';
    map.forEach(string => newString += string);
    return newString;
  }

  /**
   * Get the key of a value in an object
   * @param object The object to search through
   * @param value The value to search
   * @returns The key in the object if it exists
   */
  public getKeyByValue(object: { [x: string]: any; }, value: any) {
    return Object.keys(object).find(key => object[key] === value);
  }

  /**
   * Find the common elements in two arrays
   * @param array1 The first array
   * @param array2 The second array
   * @returns The common elements
   */
  public findCommonElements = (array1: any[], array2: any[]) => {
    try {
      const set = new Set(array1);
      const commonElements = array2.filter(element => set.has(element));
      return commonElements;
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - findCommonElements`, error);
    }
  }

  /**
   * Get a uuid for dynamo purposes
   * @returns New id
   */
  public getUuid = () => {
    return uuid();
  }

  /**
   * Convert Data URI to a blob
   * @param dataURI DataURI string
   * @returns Same DataURI represented as a blob
   */
  public dataURItoBlob = (dataURI: string) => {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    var ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], {type: mimeString});
    return blob;
  }

  /**
   * Convert a blob to a base64 string
   * @param blob Blob to be converted
   * @returns Base64 string
   */
  public blobToBase64 = (blob: Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(<string>reader.result);
      };
      reader.readAsDataURL(blob);
    });
  }

  /**
   * Get the time in seconds between two ISO strings
   * @param time1 First time
   * @param time2 Second time
   * @returns Seconds between the two times
   */
  public getSecondsBetweenISOStrings = (time1: string, time2: string) => {
    try {
      const date1 = new Date(time1);
      const date2 = new Date(time2);
      const diff = this.getSecondsBetweenDates(date1, date2);
      return diff;
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - getSecondsBetweenISOStrings`, error);
    }
  }

  /**
   * Get the time in seconds between two dates
   * @param date1 First date
   * @param date2 Second date
   * @returns Seconds between the two dates
   */
  public getSecondsBetweenDates = (date1: Date, date2: Date) => {
    try {
      const diff = date2.getTime() - date1.getTime();
      return diff / 1000;
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - getSecondsBetweenDates`, error);
    }
  }

  /**
   * Determine if a search has been completed and has evidence
   * @param search Search to be checked
   * @returns If the search has been completed and has evidence
   */
  public searchCompleteAndHasEvidence = (search: Search) => {
    try {
      if(!search.serviceCompleted) return false;
      else {
        let hasEvidence = false;
        search.serviceAreas?.map(serviceArea => {
          if(serviceArea.serviceEvidences && serviceArea.serviceEvidences.length) {
            hasEvidence = true;
          }
        });
        return hasEvidence;
      }
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - searchHasEvidenceFound`, error);
    }
  }
}
